import config from "../appConfig";

import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const connectionString = config.App.InsightsConnectionString;

// Add the Click Analytics plug-in.
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    customDataPrefix: "data-ai-",
    parentDataTag: "parent-group",
    donotTrackDataTag: "dnt"
  }
};

const reactPlugin = new ReactPlugin();

const appInsights = !connectionString
  ? undefined
  : new ApplicationInsights({
      config: {
        connectionString,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        overridePageViewDuration: true,
        maxAjaxCallsPerView: -1,
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
          [clickPluginInstance.identifier]: clickPluginConfig
        },
        samplingPercentage: 100
      }
    });

appInsights?.addTelemetryInitializer((x: ITelemetryItem) => {
  if (x.tags == null) return;

  x.tags["ai.application.ver"] = process.env.REACT_APP_BUILD_VERSION;
  x.tags["ai.cloud.role"] = config.App.InsightsRole;
  x.tags["ai.cloud.roleInstance"] = config.App.InsightsRole;
});

const ssoUrlRegex = /(\/mip\/(login|register))(\/[\w\-_]+)?$/i;
const authTokenRegex = /(\/auth\/refresh)(.+)?$/i;

// Sanitize login and register URIs in telemetry
appInsights?.addTelemetryInitializer(x => {
  const contextName = appInsights.getTraceCtx != null ? appInsights.getTraceCtx()?.getName() : undefined;
  if (contextName != null && ssoUrlRegex.test(contextName)) {
    const trace = appInsights.getTraceCtx!();
    trace?.setName(contextName.replace(ssoUrlRegex, "$1") || "");
  }
  if ([/RemoteDependencyData/i].some(type => type.test(x.baseType || ""))) {
    if (x?.baseData?.target != null && ssoUrlRegex.test(x.baseData.target)) {
      x.baseData.target = x?.baseData?.target.replace(ssoUrlRegex, "$1");
    }
    if (x?.baseData?.name != null && ssoUrlRegex.test(x.baseData.name)) {
      x.baseData.name = x?.baseData?.name.replace(ssoUrlRegex, "$1");
    }
  }
  if ([/PageviewData/i, /PageviewPerformanceData/i].some(type => type.test(x.baseType || ""))) {
    if (x.baseData?.uri != null) x.baseData.uri = x.baseData.uri.replace(ssoUrlRegex, "$1");
    if (x.baseData?.refUri != null) x.baseData.refUri = x.baseData.refUri.replace(ssoUrlRegex, "$1");
  }
});

// Sanitize refresh URIs in telemetry
appInsights?.addTelemetryInitializer(x => {
  const contextName = appInsights.getTraceCtx != null ? appInsights.getTraceCtx()?.getName() : undefined;
  if (contextName != null && authTokenRegex.test(contextName)) {
    const trace = appInsights.getTraceCtx!();
    trace?.setName(contextName.replace(authTokenRegex, "$1") || "");
  }

  if ([/RemoteDependencyData/i].some(type => type.test(x.baseType || ""))) {
    if (x?.baseData?.target != null && authTokenRegex.test(x.baseData.target)) {
      x.baseData.target = x?.baseData?.target.replace(authTokenRegex, "$1");
    }
    if (x?.baseData?.name != null && authTokenRegex.test(x.baseData.name)) {
      x.baseData.name = x?.baseData?.name.replace(authTokenRegex, "$1");
    }
  }

  if ([/PageviewData/i, /PageviewPerformanceData/i].some(type => type.test(x.baseType || ""))) {
    if (x.baseData?.uri != null && authTokenRegex.test(x.baseData.uri)) {
      x.baseData.uri = x.baseData.uri.replace(authTokenRegex, "$1");
    }
    if (x.baseData?.refUri != null && authTokenRegex.test(x.baseData.refUri)) {
      x.baseData.refUri = x.baseData.refUri.replace(authTokenRegex, "$1");
    }
  }
});

appInsights?.addTelemetryInitializer(x => {
  try {
    if (/PageviewData/i.test(x.baseType || "")) {
      x.data = x.data || {};
      x.data = {
        ...x.data,
        "Screen Resolution": `${window.screen.width}x${window.screen.height}`
      };
    }
  } catch {}
});

export default appInsights;
